import React from "react";
import "./errorpage.scss";
import { useNavigate } from "react-router-dom";
function Errorpage() {
  const navigate = useNavigate();
  return (
    <section className="errorpage-sec">
      <div className="inner">
        <img src="/assets/images/error.webp" alt="..." />
        <div>
          <h5>OPS! PAGE NOT FOUND</h5>
          <h6>
            Sorry, the page you're looking for doesn't exist. If you think
            something is broken, report a problem.
          </h6>
        </div>
        <button className="btnprimary" onClick={() => navigate("/")}>
          Go To home
        </button>
      </div>
    </section>
  );
}

export default Errorpage;
