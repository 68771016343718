import React from "react";
import "./services.scss";
import Banner from "../banner/banner";
import Contactus from "../contactus/contactus";

const Services = () => {
  return (
    <div style={{ background: "var(--bodycolor)" }}>
      <Banner
        vidsrc="/assets/videos/neolin tech serices.mp4"
        title="Services"
        layer="/assets/images/vidbanner.webp"
      />
      <div className="services-sec">
        <div className="inner">
          <div className="first">
            <div className="left">
              <h3>
                Blockchain <br />
                Development
              </h3>
              <span>01</span>
              <h6>
                Neolin Tech epitomizes the essence of a technological
                powerhouse, positioning itself as a catalyst for progress and
                innovation across a myriad of services. Our unwavering
                commitment is to facilitate transformative solutions, shaping a
                future where businesses not only survive but thrive in the
                ever-evolving digital landscape. At the heart of our ethos lies
                a relentless pursuit of being at the forefront of technological
                evolution. We're not just satisfied with keeping pace; we strive
                to lead the charge, consistently creating solutions that push
                the boundaries of what's possible. With a keen understanding of
                the dynamic nature of technology, we are agile and adaptive,
                ready to meet the challenges of tomorrow head-on.
              </h6>
              <button className="btnprimary">Get in touch</button>
            </div>
            <img
              src="/assets/images/blockchain.webp"
              alt="..."
              className="right"
            />
          </div>
          <div className="second">
            <div className="left">
              <h3>
                Artificial <br />
                Intelligence
              </h3>
              <span>02</span>
              <h6>
                At Neolin Tech, our AI expertise drives natural language
                processing, image recognition, and machine learning innovation.
                We develop intelligent systems that enhance automation and
                decision-making for our clients.
              </h6>
              <button className="btnprimary">Get in touch</button>
            </div>
            <img
              src="/assets/images/artificial.webp"
              alt="..."
              className="right"
            />
          </div>
          <div className="first">
            <div className="left">
              <h3>Metaverse</h3>
              <span>03</span>
              <h6>
                As pioneers in the metaverse space, Neolin Tech connects users
                through augmented reality, virtual reality, and the internet. We
                create shared virtual spaces, blurring boundaries between
                physical and digital realms for unparalleled interactive
                experiences
              </h6>
              <button className="btnprimary">Get in touch</button>
            </div>
            <img
              src="/assets/images/metaverse.webp"
              alt="..."
              className="right"
            />
          </div>
          <div className="second">
            <div className="left">
              <h3>
                Game <br />
                Development
              </h3>
              <span>04</span>
              <h6>
                Neolin Tech is a leader in creative game development, using new
                technologies like virtual reality and real-time graphics to
                create immersive and entertaining gaming experiences for diverse
                platforms.
              </h6>
              <button className="btnprimary">Get in touch</button>
            </div>
            <img src="/assets/images/game.webp" alt="..." className="right" />
          </div>
          <div className="first">
            <div className="left">
              <h3>
                DevOps <br />
                Services
              </h3>
              <span>05</span>
              <h6>
                Neolin Techv implements DevOps practices to accelerate software
                development and deployment. Our collaborative approach,
                automation, and continuous delivery ensure efficiency and
                high-quality outcomes for our clients' projects
              </h6>
              <button className="btnprimary">Get in touch</button>
            </div>
            <img src="/assets/images/devops.webp" alt="..." className="right" />
          </div>
          <div className="second">
            <div className="left">
              <h3>
                Sales &<br />
                Marketing
              </h3>
              <span>06</span>
              <h6>
                Sales and marketing involve strategies to promote and sell
                products or services. In the digital age, it includes online and
                offline tactics, leveraging social media, content marketing, and
                data analytics to enhance customer engagement and drive business
                growth.
              </h6>
              <button className="btnprimary">Get in touch</button>
            </div>
            <img src="/assets/images/sales.webp" alt="..." className="right" />
          </div>
        </div>
        <img
          src="/assets/images/servicesleftback.webp"
          alt="..."
          className="left-back"
        />
        <img
          src="/assets/images/servicesrightback.webp"
          alt="..."
          className="right-back"
        />
      </div>
      <Contactus />
    </div>
  );
};

export default Services;
