import React from "react";
import "./footer.scss";
import { useNavigate } from "react-router-dom";
function Footer() {
  const navigate = useNavigate();

  return (
    <section className="footer-sec">
      <img
        src="/assets/images/footertop.webp"
        alt="..."
        className="footer-top"
      />
      <div className="footer-bottom">
        <div className="ist">
          <img
            src="/assets/icons/fINL LOGO.svg"
            alt="..."
            className="footer-logo"
            onClick={() => navigate("/")}
          />
          <p>
            At Neolin Tech, we're at the forefront of IT innovation, driving
            excellence through a diverse range of cutting-edge services.
          </p>
        </div>
        <div className="second">
          <h5 className="heading">Pages</h5>
          <a href="/privacy-policy">Privacy Policy</a>
          <a href="/terms-and-conditions">Terms of service</a>
        </div>
        <div className="third">
          <h5 className="heading">Explore</h5>
          <a href="/about-us">About</a>
          <a href="/contact-us">Contact</a>
        </div>
        <div className="forth">
          <h5 className="heading">Pages</h5>
          <div className="social-icons">
            <a href="/" target="_blank">
              <img src="/assets/icons/footerdiscord.svg" alt="..." />
            </a>
            <a href="https://www.instagram.com/neolintech/" target="_blank">
              <img src="/assets/icons/footerinsta.svg" alt="..." />
            </a>
            <a href="https://www.facebook.com/neolintech/" target="_blank">
              <img src="/assets/icons/footerfacebook.svg" alt="..." />
            </a>
            <a href="https://twitter.com/neolin_tech" target="_blank">
              <img src="/assets/icons/footertwitter.svg" alt="..." />
            </a>
          </div>
          <p>@2024 Neolin Tech. All Rights Reserved</p>
        </div>
      </div>
    </section>
  );
}

export default Footer;
