import React from "react";
import "./trustedbrands.scss";
function Trustedbrands() {
  return (
    <section className="trustedbrands-sec">
      <div className="head">
        <h3>Trusted Brands</h3>
      </div>
      <div className="inner">
        <div className="left">
          <h2>
            We proudly support brands known for quality, trust, and innovation.
          </h2>
          <h6>
            Explore our Portfolio of Client-Centric Projects and the journeys
            we've shared in building remarkable solutions.
          </h6>
        </div>
        <div className="right">
          <img src="/assets/images/trustedbrands.webp" alt="" />
        </div>
      </div>
      <img
        src="/assets/images/trustedback2.webp"
        alt="..."
        className="back-img"
      />
      <img
        src="/assets/images/trustedleft2.png"
        alt="..."
        className="back-img2"
      />
    </section>
  );
}

export default Trustedbrands;
