import React, { useEffect, useState } from "react";
import "./contactus.scss";
function Contactus() {
  const initialValues = {
    name: "",
    phone: "",
    email: "",
    description: "",
  };
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setFormErrors(validate(formValues));
  };
  useEffect(() => {
    if (Object.keys(formErrors).length == 0 && isSubmit);
  }, [formErrors]);
  const validate = (values) => {
    const errors = {};
    let check = false;
    const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

    if (!values.email) {
      errors.email = "Email is required";
      check = true;
    } else if (!regex.test(values.email)) {
      errors.email = "This is not a valid Email Format";
      check = true;
    }
    if (!values.name) {
      errors.name = "Name is required";
      check = true;
    }
    if (!values.number) {
      errors.number = "Phone is required";
      check = true;
    }

    return errors;
  };

  return (
    <section className="contactus-sec">
      <div className="inner">
        <div className="left">
          <h3>GROW YOUR BUSINESS &</h3>
          <h4>EXPERIENCE ENDLESS POSIBILITIES</h4>
        </div>
        <div className="right">
          <input
            type="text"
            placeholder="Name"
            value={formValues.name}
            onChange={handleChange}
          />
          <span className="validation-error">{formErrors.name}</span>
          <input
            type="number"
            placeholder="Phone"
            value={formValues.number}
            onChange={handleChange}
          />
          <span className="validation-error">{formErrors.number}</span>
          <input
            type="email"
            placeholder="Email"
            value={formValues.email}
            onChange={handleChange}
          />
          <span className="validation-error">{formErrors.email}</span>

          <textarea placeholder="Description" rows={9}></textarea>
          <button className="btnprimary" onClick={handleSubmit}>
            Submit
          </button>
        </div>
      </div>
    </section>
  );
}

export default Contactus;
