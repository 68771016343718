import { Route, Routes, useLocation } from "react-router-dom";
import "./App.css";
import Aboutcontent from "./components/aboutcontent/aboutcontent";
import Blog from "./components/blog/blog";
import Blogs from "./components/blogs/blogs";
import Contactus from "./components/contactus/contactus";
import Counter from "./components/counter/counter";
import Faqs from "./components/faqs/faqs";
import Footer from "./components/footer/footer";
import Hero from "./components/hero/hero";
import Homeservices from "./components/homeservices/homeservices";
import Landingabout from "./components/landingabout/landingabout";
import Navbar from "./components/navbar/navbar";
import Portfolio from "./components/portfolio/portfolio";
import Team from "./components/team/team";
import Trustedbrands from "./components/trustedbrands/trustedbrands";
import Whyus from "./components/whyus/whyus";
import Home from "./components/home/home";
import Termsconditions from "./components/termsconditions/termsconditions";
import Privacypolicy from "./components/privacypolicy/privacypolicy";
import Aboutuspage from "./components/aboutuspage/aboutuspage";
import Banner from "./components/banner/banner";
import Services from "./components/services/services";
import { useEffect } from "react";
import Errorpage from "./components/errorpage/errorpage";
import Contactuspage from "./components/contactuspage/contactuspage";
const navBarRoutes = [
  "/",
  "/terms-and-conditions",
  "/privacy-policy",
  "/about-us",
  "/blogs",
  "/blog",
  "/portfolio",
  "/services",
  "/contact-us",
];

function App() {
  const location = useLocation();

  return (
    <div className="App">
      {navBarRoutes.includes(location.pathname) ? (
        <>
          <Navbar />
        </>
      ) : (
        <></>
      )}

      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/terms-and-conditions" element={<Termsconditions />} />
        <Route path="/privacy-policy" element={<Privacypolicy />} />
        <Route path="/about-us" element={<Aboutuspage />} />
        <Route path="/blogs" element={<Blogs />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/portfolio" element={<Portfolio />} />
        <Route path="/services" element={<Services />} />
        <Route path="/contact-us" element={<Contactuspage />} />
        <Route path="*" element={<Errorpage />} />
      </Routes>
      {navBarRoutes.includes(location.pathname) ? (
        <>
          <Footer />
        </>
      ) : (
        <></>
      )}
    </div>
  );
}

export default App;
