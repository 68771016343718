import React from "react";
import "./faqs.scss";
import { Accordion } from "react-bootstrap";
function Faqs() {
  return (
    <section className="faqs-sec">
      <div className="inner">
        <div className="head">
          <h3>FAQS</h3>
        </div>
        <div className="faqs-accordion">
          <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                Can Neolin Tech handle projects of different scales?
              </Accordion.Header>
              <Accordion.Body>
                Absolutely. We cater to projects of varying sizes, from startups
                to established enterprises. Our adaptable teams ensure that each
                project receives the attention and expertise it deserves.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>
                What sets Neolin Tech apart in the tech industry?
              </Accordion.Header>
              <Accordion.Body>
                Neolin Tech stands out for its commitment to excellence,
                innovative solutions, and a client-centric approach. Our diverse
                portfolio and successful collaborations underscore our industry
                distinction
              </Accordion.Body>
            </Accordion.Item>
            {/* <Accordion.Item eventKey="2">
              <Accordion.Header>
                Can Neolin Tech handle projects of different scales?
              </Accordion.Header>
              <Accordion.Body>
                Absolutely. We cater to projects of varying sizes, from startups
                to established enterprises. Our adaptable teams ensure that each
                project receives the attention and expertise it deserves.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3">
              <Accordion.Header>
                What sets Neolin Tech apart in the tech industry?
              </Accordion.Header>
              <Accordion.Body>
                Neolin Tech stands out for its commitment to excellence,
                innovative solutions, and a client-centric approach. Our diverse
                portfolio and successful collaborations underscore our industry
                distinction.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="4">
              <Accordion.Header>
                Which industries does Neolin Tech specialize in?
              </Accordion.Header>
              <Accordion.Body>
                Neolin Tech serves various industries, including healthcare,
                finance, e-commerce, manufacturing, education, and
                telecommunications.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="5">
              <Accordion.Header>
                Does Neolin Tech provide post-project support?
              </Accordion.Header>
              <Accordion.Body>
                Yes, Neolin Tech offers ongoing support and maintenance services
                to ensure smooth system operations post-project delivery.
              </Accordion.Body>
            </Accordion.Item> */}
          </Accordion>
        </div>
      </div>
      <img src="/assets/images/faqleftback.webp" alt="" className="left-back" />
      <img
        src="/assets/images/faqrightback.webp"
        alt=""
        className="right-back"
      />
    </section>
  );
}

export default Faqs;
