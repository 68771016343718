import React from "react";
function Privacypolicy() {
  return (
    <section className="terms-sec">
      <h2 className="heading">Privacy Policy</h2>
      <div className="content">
        <h2>Introduction</h2>
        <h6>
          Welcome to Neolin Tech. Your privacy matters to us, and we are
          committed to ensuring the protection of your personal information.
          This Privacy Policy outlines how we collect, use, and safeguard your
          data when you access our website and use our services. By using our
          website and services, you agree to the terms outlined in this policy.
        </h6>
        <div>
          <h5>1. Information We Collect</h5>
          <section>
            <h6>
              At Neolin Tech, we collect both personal and non-personal
              information to provide and improve our services. The types of
              information we may collect include:
            </h6>
            <ul>
              <li>
                Personal Information: This may include your name, email address,
                contact information, and any other information you voluntarily
                provide to us.
              </li>
              <li>
                Non-Personal Information: This may include device information,
                log data, and usage information collected through cookies and
                similar technologies.
              </li>
            </ul>
          </section>
        </div>
        <div>
          <h5>2. How We Use Your Information</h5>
          <section>
            <h6>
              We use the information we collect for various purposes, including:
            </h6>
            <ul>
              <li>To provide and maintain our services</li>
              <li>To communicate with you and respond to your inquiries</li>
              <li>To personalize your experience and improve our website</li>
              <li>To analyze usage trends and optimize our services</li>
            </ul>
          </section>
        </div>
        <div>
          <h5>3. Information Sharing and Disclosure</h5>
          <section>
            <h6>
              We may share your information with third parties in the following
              circumstances:
            </h6>
            <ul>
              <li>With your consent</li>
              <li>
                To comply with legal obligations or enforce our terms of service
              </li>
              <li>
                To protect the rights, property, or safety of Neolin Tech, our
                users, or others
              </li>
              <li>
                In connection with a merger, acquisition, or sale of assets
              </li>
            </ul>
          </section>
        </div>
        <div>
          <h5>4. Data Security</h5>
          <h6>
            We implement appropriate security measures to protect your
            information from unauthorized access, alteration, disclosure, or
            destruction. However, please note that no method of transmission
            over the internet or electronic storage is 100% secure, and we
            cannot guarantee absolute security.
          </h6>
        </div>
        <div>
          <h5>5. Your Choices</h5>
          <h6>
            You have the right to access, update, or delete your personal
            information. You may also opt-out of receiving promotional
            communications from us. Please note that certain services may be
            unavailable if you choose to delete your information or opt-out of
            certain communications.
          </h6>
        </div>
        <div>
          <h5>6. Children's Privacy</h5>
          <h6>
            Our services are not intended for children under the age of 13. We
            do not knowingly collect personal information from children under
            the age of 13. If you believe that we have inadvertently collected
            information from a child under 13, please contact us immediately.
          </h6>
        </div>
        <div>
          <h5>7. Changes to this Privacy Policy</h5>
          <h6>
            We reserve the right to update or change this Privacy Policy at any
            time. Any changes will be effective immediately upon posting the
            revised policy on our website. We encourage you to review this
            Privacy Policy periodically for any updates.
          </h6>
        </div>
        <div>
          <h5>8. Contact Us</h5>
          <h6>
            If you have any questions or concerns about this Privacy Policy or
            our privacy practices, please contact us at [contact email]. We are
            dedicated to addressing any inquiries you may have and ensuring your
            privacy is protected.
          </h6>
        </div>
      </div>
    </section>
  );
}

export default Privacypolicy;
