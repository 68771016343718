import React, { useEffect, useState } from "react";
import "./hero.scss";
import { useTypewriter, Cursor } from "react-simple-typewriter";
import backvideo from "../../assets/videos/hero.mp4";
function Hero() {
  const [imagePosition, setImagePosition] = useState("-100%");
  const [bottomimagePosition, setBottomImagePosition] = useState("-100%");
  const [text] = useTypewriter({
    words: ["Business"],
    loop: 1000,
  });
  useEffect(() => {
    setImagePosition("0%");
    setBottomImagePosition("0%");
  }, []);
  return (
    <section className="hero-sec">
      {/* <img
        src="/assets/images/herotop.webp"
        alt="..."
        className="hero-top"
        style={{ top: imagePosition }}
      />
      <img src="/assets/images/heroleft.webp" alt="..." className="hero-left" />
      <img
        src="/assets/images/heroright.webp"
        alt="..."
        className="hero-right"
      /> */}

      {/* <video src="/assets/videos/hero-back-vidio.mp4"></video> */}

      <div className="video-container">
        <video autoPlay loop muted playsInline>
          <source src="\assets\videos\hero.mp4" type="video/mp4" />
        </video>
      </div>

      <div className="inner">
        <h1 className="text-white">
          Most Experience IT <br />
          Solution Agency for Your{" "}
          <span style={{ color: "#71B11A" }}>{text}</span>
          <Cursor cursorColor="#71B11A" />
        </h1>
        <h6 className="text-white">
          Forge ahead with our transformative IT solutions, molding the tech
          frontier of tomorrow, dedicated to making your journey to success
          smooth and brimming with opportunities.
        </h6>
        <button className="btnprimary">Explore</button>
      </div>
      {/* <img
        style={{ bottom: bottomimagePosition }}
        src="/assets/images/herobottom.webp"
        alt="..."
        className="hero-bottom"
      /> */}
      <div className="social-icons">
        <a href="https://www.instagram.com/neolintech/" target="_blank">
          <img src="/assets/icons/insta.svg" alt="..." />
        </a>
        <a href="https://twitter.com/neolin_tech" target="_blank">
          <img src="/assets/icons/twitter.svg" alt="..." />
        </a>
        <a href="https://www.facebook.com/neolintech/" target="_blank">
          <img src="/assets/icons/facebook.svg" alt="..." />
        </a>
      </div>
    </section>
  );
}
export default Hero;
