import React, { useEffect } from "react";
import Hero from "../hero/hero";
import Landingabout from "../landingabout/landingabout";
import Homeservices from "../homeservices/homeservices";
import Trustedbrands from "../trustedbrands/trustedbrands";
import Whyus from "../whyus/whyus";
import Team from "../team/team";
import Faqs from "../faqs/faqs";
import Contactus from "../contactus/contactus";
import Testimonials from "../swiper/swiper";
import Homeservicesnew from "../homeservicesnew/homeservicesnew";

function Home() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <section className="home-sec">
      <Hero />
      <Landingabout />
      {/* <Homeservices /> */}
      <Homeservicesnew />
      <Trustedbrands />
      <Whyus />
      <Testimonials />
      <Team />
      <Faqs />
      <Contactus />
    </section>
  );
}

export default Home;
