import React, { useEffect } from "react";
import "./blogs.scss";
import Banner from "../banner/banner";
import { useNavigate } from "react-router-dom";
function Blogs() {
  const navigate = useNavigate();

  return (
    <>
      <Banner
        imgsrc="/assets/images/blogsback.webp"
        title="Explore our Insights"
        layer="/assets/images/vidbanner.webp"
      />
      <section className="blogs-sec">
        <div className="inner">
          {data.map((item) => (
            <div
              className="blog-card"
              onClick={() => navigate("/blog", { state: item })}
            >
              <div className="top">
                <img src="/assets/images/blog.webp" alt="..." />
              </div>
              <div className="bottom">
                <div className="heading">
                  <h5>{item.title}</h5>
                  {/* <img src="/assets/icons/arrowup.svg" alt="..." /> */}
                </div>
                <p className="content">{item.para.substring(0, 200)}...</p>
                <div className="detail">
                  <img
                    src="/assets/icons/user.svg"
                    alt="..."
                    className="user"
                  />
                  <div className="username">
                    <h6>Drew</h6>
                    <span>20 Jan 2022</span>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
        <img
          src="/assets/images/blogsleftback.webp"
          alt="..."
          className="left-back"
        />
        <img
          src="/assets/images/blogsrightback.webp"
          alt="..."
          className="right-back"
        />
      </section>
    </>
  );
}

export default Blogs;

const data = [
  {
    imgsrc: "/assets/images/blog.webp",
    title: "Unveiling Web3: The Evolution of Decentralized Networks",
    para: "Web3 represents a paradigm shift in how we interact with the internet, moving towards decentralized networks that prioritize user control and data ownership. Unlike its predecessors, Web3 is built on blockchain technology, enabling trustless transactions and immutable data storage. At its core, Web3 aims to empower users by giving them more agency over their digital identities and assets. One of the key components of Web3 is the use of smart contracts, self-executing contracts with the terms of the agreement directly written into code. Smart contracts enable automated and transparent transactions, eliminating the need for intermediaries and reducing the risk of fraud. Another aspect of Web3 is the rise of decentralized applications (DApps), which run on blockchain networks and operate without a central authority. These DApps span a wide range of industries, from finance to gaming, and offer users unprecedented levels of security and privacy. As Web3 continues to evolve, it has the potential to reshape the internet as we know it, fostering greater innovation and inclusivity. By embracing the principles of decentralization and user sovereignty, Web3 has the power to democratize access to information and resources, ushering in a new era of digital empowerment.",
    userimg: "/assets/icons/user.svg",
    usernmae: "Drew Cano",
    data: "20 Jan 2022",
  },
  {
    imgsrc: "/assets/images/blog.webp",
    title: "Blockchain's Impact on Supply Chain Sustainability",
    para: "Blockchain technology is revolutionizing supply chain management, particularly in promoting sustainability and transparency. By providing a decentralized and immutable ledger, blockchain enables companies to track products from raw materials to the end consumer, ensuring ethical sourcing and reducing environmental impact. This transparency fosters trust among consumers and stakeholders, encouraging companies to adopt more sustainable practices. Additionally, blockchain-based supply chains can streamline processes, reduce paperwork, and eliminate intermediaries, leading to cost savings and efficiency gains. With blockchain, consumers can verify the authenticity and origin of products, empowering them to make more informed purchasing decisions. Overall, blockchain has the potential to transform supply chains into more sustainable and responsible ecosystems, benefiting both businesses and society as a whole.",
    userimg: "/assets/icons/user.svg",
    usernmae: "Drew Cano",
    data: "20 Jan 2022",
  },
  {
    imgsrc: "/assets/images/blog.webp",
    title: "Machine Learning in Healthcare: Transforming Patient Care",
    para: "Machine learning is revolutionizing healthcare by enabling more accurate diagnoses, personalized treatments, and predictive analytics. By analyzing vast amounts of patient data, machine learning algorithms can identify patterns and trends that human doctors might miss, leading to earlier detection of diseases and better treatment outcomes. From medical imaging to drug discovery, machine learning is driving innovation across the healthcare industry, ultimately improving patient care and saving lives. Moreover, machine learning can help healthcare providers optimize resource allocation, reduce costs, and improve operational efficiency. However, the widespread adoption of machine learning in healthcare also raises important ethical and privacy considerations, such as data security, consent, and algorithmic bias. By addressing these challenges and leveraging machine learning responsibly, we can harness its full potential to revolutionize healthcare delivery and improve patient outcomes.",
    userimg: "/assets/icons/user.svg",
    usernmae: "Drew Cano",
    data: "20 Jan 2022",
  },
  {
    imgsrc: "/assets/images/blog.webp",
    title: "NFTs: Redefining Ownership in the Digital Age",
    para: "Non-fungible tokens (NFTs) are revolutionizing the way we buy, sell, and own digital assets, particularly in the realm of art and collectibles. Unlike cryptocurrencies such as Bitcoin, which are fungible and interchangeable, NFTs are unique and indivisible, representing ownership of a specific digital item. This scarcity and authenticity make NFTs valuable as digital collectibles, enabling creators to monetize their work in new ways. However, the rise of NFTs has also sparked debates about ownership rights, intellectual property, and environmental concerns due to the energy-intensive nature of blockchain transactions. Despite these challenges, NFTs have the potential to democratize access to art and culture, empower creators, and create new economic opportunities in the digital age.",
    userimg: "/assets/icons/user.svg",
    usernmae: "Drew Cano",
    data: "20 Jan 2022",
  },
  {
    imgsrc: "/assets/images/blog.webp",
    title: "Decentralized Finance (DeFi): The Future of Banking",
    para: "Decentralized finance (DeFi) is disrupting traditional banking systems by offering a wide range of financial services on blockchain networks, including lending, borrowing, and trading. By eliminating intermediaries and leveraging smart contracts, DeFi platforms can provide users with greater control over their assets and lower transaction fees. Additionally, DeFi opens up access to financial services for underserved populations around the world, promoting financial inclusion and empowerment. However, DeFi also poses risks such as smart contract vulnerabilities and regulatory uncertainty, highlighting the need for careful consideration and risk management in this rapidly evolving space. Despite these challenges, DeFi has the potential to revolutionize the global financial system, making it more accessible, efficient, and inclusive for everyone.",
    userimg: "/assets/icons/user.svg",
    usernmae: "Drew Cano",
    data: "20 Jan 2022",
  },
  {
    imgsrc: "/assets/images/blog.webp",
    title: "Ethical Considerations in AI Development",
    para: "As artificial intelligence (AI) becomes more prevalent in our lives, it's crucial to consider the ethical implications of its development and deployment. Issues such as algorithmic bias, privacy violations, and job displacement raise important questions about fairness, accountability, and social impact. Ethical AI frameworks aim to address these concerns by promoting transparency, fairness, and inclusivity in AI systems. This includes measures such as data anonymization, algorithmic audits, and diverse representation in AI development teams. By prioritizing ethics in AI development, we can ensure that AI technologies serve the greater good and contribute to a more equitable and sustainable future. Moreover, ethical AI development is essential for building trust among users and stakeholders, fostering adoption, and maximizing the potential benefits of AI while minimizing the risks.",
    userimg: "/assets/icons/user.svg",
    usernmae: "Drew Cano",
    data: "20 Jan 2022",
  },
];
