import React from "react";
import "./contactuspage.scss";
function Contactuspage() {
  return (
    <section className="contactuspage-sec">
      <div className="contactbanner">
        <div className="top"></div>
        <h1 className="center">Contact us</h1>
        <div className="bottom">
          <div className="emails">
            <div>
              <h6>info@neolintech.com</h6>
            </div>
            {/* <div>
              <h6>info@neolintech.com</h6>
            </div>
            <div>
              <h6>info@neolintech.com</h6>
            </div> */}
          </div>
          <div className="address">
            {/* <img src="/assets/icons/location.svg" alt="..." />
            <h6>Your Address</h6> */}
          </div>
        </div>
      </div>
      <div className="contact-content">
        <div className="contact-card">
          <div className="left">
            <h3>Get in Touch</h3>
            <input type="text" placeholder="Name *" />
            <input type="email" placeholder="Email *" />
            <input type="number" placeholder="Phone number " />
            <p>What services are you interested in?</p>
            <div className="checkboxes">
              <div class="round">
                <input type="checkbox" id="blockchain" />
                <label for="blockchain" className="circle-box"></label>
                <label for="blockchain" className="pointer">Blockchain</label>

              </div>
              <div class="round">
                <input type="checkbox" id="ai" />
                <label for="ai" className="circle-box"></label>
                <label for="ai"className="pointer">Artificial Intelligence</label>

              </div>

              <div class="round">
                <input type="checkbox" id="metaverse" />
                <label for="metaverse" className="circle-box"></label>
                <label for="metaverse"className="pointer">Metaverse</label>

              </div>
              <div class="round">
                <input type="checkbox" id="game" />
                <label for="game" className="circle-box"></label>
                <label for="game " className="pointer">Game Development</label>
              </div>
              <div class="round">
                <input type="checkbox" id="devops" />
                <label for="devops" className="circle-box"></label>
                <label for="devops"className="pointer">DevOps Service</label>

              </div>
              <div class="round">
                <input type="checkbox" id="sales" />
                <label for="sales" className="circle-box"></label>
                <label for="sales"className="pointer">Sales & Marketing</label>

              </div>
              
            </div>
            <textarea rows="6" placeholder="Description"></textarea>
            <button className="btnprimary">SEND</button>
          </div>
          <div className="right">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3608.3988458027984!2d55.296585076355626!3d25.257165129267424!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f4334ae8f9bb1%3A0x4161ac31d11f9e58!2sThe%20Atrium%20Center!5e0!3m2!1sen!2s!4v1708342839132!5m2!1sen!2s"
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
          <div className="colored"></div>
        </div>
      </div>
    </section>
  );
}

export default Contactuspage;
