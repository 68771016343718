import React, { useEffect, useState } from "react";
import "./navbar.scss";
import Hamburger from "hamburger-react";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";

function Navbar() {
  const navigate = useNavigate();

  const [isOpen, setOpen] = useState(false);
  const location = useLocation();
  const changedPages = [
    "/about-us",
    "/services",
    "/portfolio",
    "/blogs",
    "/contact-us",
    "/"
  ];
  const getAppropiateNavItemsColor = () =>
    changedPages.includes(location.pathname) ? { color: "white" } : {};
  const handleclick = () => {
    navigate("/contact-us");
    setOpen(false);
  };

  return (
    <>
      <section className="navbar-sec d-md-block d-none">
        <nav class="navbar navbar-expand-lg ">
          <Link class="navbar-brand" to="/">
            <img
              src={`${
                changedPages.includes(location.pathname)
                  ? "/assets/icons/fINAL LOGO.svg"
                  : "/assets/icons/fINL LOGO.svg"
              }`}
              alt=""
            />
          </Link>
          <button
            class="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <Hamburger toggled={isOpen} toggle={setOpen} />
          </button>

          <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav mx-auto">
              <li class="nav-item">
                <NavLink
                  class="nav-link"
                  to="/"
                  style={getAppropiateNavItemsColor}
                >
                  Home
                </NavLink>
              </li>
              <li class="nav-item">
                <NavLink
                  class="nav-link"
                  to="/about-us"
                  style={getAppropiateNavItemsColor}
                >
                  About Us
                </NavLink>
              </li>
              <li class="nav-item">
                <NavLink
                  class="nav-link"
                  to="/services"
                  style={getAppropiateNavItemsColor}
                >
                  Services
                </NavLink>
              </li>
              <li class="nav-item">
                <NavLink
                  class="nav-link"
                  to="/portfolio"
                  style={getAppropiateNavItemsColor}
                >
                  Portfolio
                </NavLink>
              </li>
              <li class="nav-item">
                <NavLink
                  class="nav-link"
                  to="/blogs"
                  style={getAppropiateNavItemsColor}
                >
                  Blogs
                </NavLink>
              </li>
            </ul>
            <button
              class="btnprimary"
              type="submit"
              onClick={() => navigate("/contact-us")}
            >
              Contact Us
            </button>
          </div>
        </nav>
      </section>
      <section className="navbar-sec d-md-none d-block">
        <nav class="navbar navbar-expand-lg ">
          <Link class="navbar-brand" to="/">
            <img
              src={`${
                changedPages.includes(location.pathname)
                  ? "/assets/icons/fINAL LOGO.svg"
                  : "/assets/icons/fINL LOGO.svg"
              }`}
              alt=""
            />
          </Link>
          <button
            class="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <Hamburger
              toggled={isOpen}
              toggle={setOpen}
              color={
                changedPages.includes(location.pathname) ? "white" : "black"
              }
            />
          </button>

          {isOpen ? (
            <div class="collapse navbar-collapse" id="navbarSupportedContent">
              <ul class="navbar-nav mx-auto">
                <li class="nav-item">
                  <NavLink
                    class="nav-link"
                    to="/"
                    onClick={() => setOpen(false)}
                  >
                    Home
                  </NavLink>
                </li>
                <li class="nav-item">
                  <NavLink
                    class="nav-link"
                    to="/about-us"
                    onClick={() => setOpen(false)}
                  >
                    About Us
                  </NavLink>
                </li>
                <li class="nav-item">
                  <NavLink
                    class="nav-link"
                    to="/services"
                    onClick={() => setOpen(false)}
                  >
                    Service
                  </NavLink>
                </li>
                <li class="nav-item">
                  <NavLink
                    class="nav-link"
                    to="/portfolio"
                    onClick={() => setOpen(false)}
                  >
                    Portfolio
                  </NavLink>
                </li>
                <li class="nav-item">
                  <NavLink
                    class="nav-link"
                    to="/blogs"
                    onClick={() => setOpen(false)}
                  >
                    Blogs
                  </NavLink>
                </li>
              </ul>
              <button class="btnprimary" type="submit" onClick={handleclick}>
                Contact Us
              </button>
            </div>
          ) : (
            ""
          )}
        </nav>
      </section>
    </>
  );
}

export default Navbar;
