import React from "react";
import "./aboutcontent.scss";
function Aboutcontent() {
  return (
    <div className="aboutpage-sec">
      <section className="aboutcontent-sec">
        <div className="left">
          <img
            src="/assets/images/about.webp"
            alt="..."
            className="imgshadow"
          />
        </div>
        <div className="right">
          <h3>Who Are We?</h3>
          <div>
            <h6>
              Neolin Tech epitomizes the essence of a technological powerhouse,
              positioning itself as a catalyst for progress and innovation
              across a myriad of services. Our unwavering commitment is to
              facilitate transformative solutions, shaping a future where
              businesses not only survive but thrive in the ever-evolving
              digital landscape.
            </h6>
            <h6>
              At the heart of our ethos lies a relentless pursuit of being at
              the forefront of technological evolution. We're not just satisfied
              with keeping pace; we strive to lead the charge, consistently
              creating solutions that push the boundaries of what's possible.
              With a keen understanding of the dynamic nature of technology, we
              are agile and adaptive, ready to meet the challenges of tomorrow
              head-on.
            </h6>
          </div>
        </div>
      </section>
      <img
        src="/assets/images/aboutleftback.webp"
        alt="..."
        className="left-back"
      />
      <img
        src="/assets/images/aboutrightback.webp"
        alt="..."
        className="right-back"
      />
    </div>
  );
}

export default Aboutcontent;
