import React from "react";
import "./termsconditions.scss";
function Termsconditions() {
  return (
    <section className="terms-sec">
      <h2 className="heading">Terms & Conditions</h2>
      <div className="content">
        <h2>Introduction</h2>
        <h6>
          Welcome to Neolin Tech. By accessing our website and using our
          services, you agree to comply with and be bound by the following terms
          and conditions, which outline your rights and obligations while
          interacting with our platform.
        </h6>
        <div>
          <h5>1. Acceptance of Terms</h5>
          <h6>
            By accessing or using our website and services, you acknowledge that
            you have read, understood, and agree to be bound by these terms.
            This means that you consent to abide by the rules and guidelines
            we've established for using our platform. If you do not agree with
            any part of these terms, please refrain from using our website and
            services.
          </h6>
        </div>
        <div>
          <h5>2. Changes to Terms</h5>
          <h6>
            Neolin Tech reserves the right to update, change, or replace any
            part of these terms as necessary. It is important for you to stay
            informed about any modifications, so we encourage you to
            periodically review these terms. Your continued use of the website
            following any modifications indicates your acceptance of those
            changes.
          </h6>
        </div>
        <div>
          <h5>3. Use of Services</h5>
          <h6>
            When using Neolin Tech's services, you agree to do so for lawful
            purposes and in compliance with all applicable laws and regulations.
            This means that you should not engage in any illegal activities or
            violate any laws in your jurisdiction while using our platform. We
            expect our users to uphold ethical and legal standards in their
            interactions with our services.
          </h6>
        </div>
        <div>
          <h5>4. Intellectual Property</h5>
          <h6>
            All content, trademarks, logos, and intellectual property displayed
            on the Neolin Tech website belong to us and are protected by
            copyright and trademark laws. This means that you cannot reproduce,
            distribute, or use any content without our explicit permission. We
            value and protect our intellectual property rights, and we expect
            our users to respect them as well.
          </h6>
        </div>
        <div>
          <h5>5. Privacy Policy</h5>
          <h6>
            Your privacy is important to us at Neolin Tech. We are committed to
            safeguarding your data and ensuring that your personal information
            is handled securely. Our Privacy Policy outlines how we collect,
            use, and protect your data when you use our services. It's our
            responsibility to prioritize your privacy and maintain transparency
            in our data practices.
          </h6>
        </div>
        <div>
          <h5>6. Disclaimer of Warranties</h5>
          <h6>
            Neolin Tech provides its services on an "as-is" and "as-available"
            basis, meaning that we do not guarantee the accuracy, completeness,
            or timeliness of information available on our website. While we
            strive to provide reliable services, your use of our platform is at
            your own risk. We cannot guarantee that our services will always
            meet your expectations or be error-free.
          </h6>
        </div>
        <div>
          <h5>7. Limitation of Liability</h5>
          <h6>
            Neolin Tech and its affiliates, directors, employees, or agents
            shall not be liable for any direct, indirect, incidental, special,
            or consequential damages resulting from the use or inability to use
            our services. This means that we are not responsible for any losses
            or damages incurred as a result of using our platform, including but
            not limited to financial losses, data loss, or interruption of
            services.
          </h6>
        </div>
        <div>
          <h5>8. Contact Information</h5>
          <h6>
            If you have any questions or concerns about our terms and
            conditions, privacy practices, or any other aspect of our services,
            please don't hesitate to contact us. Our dedicated team is here to
            assist you and address any inquiries you may have. We value your
            trust and are committed to providing you with a secure and
            transparent digital experience at Neolin Tech.
          </h6>
        </div>
      </div>
    </section>
  );
}

export default Termsconditions;
