import React from "react";
import "./landingabout.scss";
function Landingabout() {
  return (
    <section className="landingabout-sec">
      <div className="head">
        <h3>About Us</h3>
      </div>
      <div className="inner">
        <div className="left">
          <img
            src="/assets/images/landingabout.webp"
            alt="..."
            className="imgshadow"
          />
        </div>
        <div className="right">
          <h3>Who Are We?</h3>
          <h6>
            Born from a shared vision and fueled by relentless determination,
            our story is one of passion and purpose. With every challenge we've
            faced and every milestone we've achieved, our bond grows stronger.
            Together, we're not just building a business; we're creating a
            legacy of innovation and impact.
          </h6>
          <button className="btnprimary">Read Case studies</button>
        </div>
      </div>
    </section>
  );
}

export default Landingabout;
