import React from "react";
import "./team.scss";
function Team() {
  return (
    <section className="team-sec">
      <div className="head">
        <h3>Meet Our Team</h3>
      </div>
      <div className="team-cards">
        <div className="cards-inner">
          {data.map((item) => (
            <div className="team-card">
              <img src={item.imgsrc} alt="..." />
              <div className="detail-div">
                <h6>{item.name}</h6>
                <p>{item.title}</p>
              </div>
            </div>
          ))}
        </div>
        <div className="back-div"></div>
      </div>
    </section>
  );
}

export default Team;

const data = [
  {
    imgsrc: "/assets/images/team1.webp",
    name: "Dean Johaness",
    title: "Chief Marketing",
  },
  {
    imgsrc: "/assets/images/team2.webp",
    name: "Sarah Aileah",
    title: "Manager",
  },
  {
    imgsrc: "/assets/images/team3.webp",
    name: "Johaness",
    title: "CEO ",
  },
  {
    imgsrc: "/assets/images/team4.webp",
    name: "Danial",
    title: "UI/UX",
  },
];
