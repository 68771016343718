import React from "react";
import "./counter.scss";
function Counter() {
  return (
    <section className="counter-sec">
      <div className="head">
        <h3>Our Path To Success</h3>
      </div>
      <div className="projects">
        <div className="ist">
          <div className="content">
            <h2>1.5k</h2>
            <h5>
              <span>Blockchain </span>Project
            </h5>
          </div>
        </div>
        <div className="ist">
          <div className="content">
            <h2>2k</h2>
            <h5>
              <span>AI </span>Project
            </h5>
          </div>
        </div>
        <div className="ist">
          <div className="content">
            <h2>9k</h2>
            <h5>
              <span>App Development </span>Project
            </h5>
          </div>
        </div>
        <div className="ist">
          <div className="content">
            <h2>3.5k</h2>
            <h5>
              <span>Web App </span>Project
            </h5>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Counter;
