import React from "react";
import "./portfolio.scss";
import Banner from "../banner/banner";
function Portfolio() {
  return (
    <>
      <Banner
        vidsrc="/assets/videos/neolin tech portfolio.mp4"
        title="Portfolio" className="bg-black"
        // layer="/assets/images/vidbanner.webp"
      />
      <section className="portfolio-sec">
        <div className="outer">
          <div className="head">
            <h3>Our Latest project</h3>
          </div>
          <div className="inner">
            <div className="portfolio-cards">
              {data.map((item) => (
                <div className="portfolio-card">
                  <img src={item.imgsrc} alt="..." className="main-img" />
                  <div className="content">
                    <h4>{item.title}</h4>
                    <span>{item.para}</span>
                  </div>
                  <div
                    className="back-img
              "
                  ></div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <img
          src="/assets/images/portfolioleftback.webp"
          alt="..."
          className="left-back"
        />
        <img
          src="/assets/images/portfoliorightback.webp"
          alt="..."
          className="right-back"
        />
      </section>
    </>
  );
}

export default Portfolio;

const data = [
  {
    imgsrc: "/assets/images/project1.webp",
    title: "TRUCKMINT",
    para: "Truck Mint revolutionizes trucking with web3 tech, creating Tesla Truck NFTs for transport management insights. This initiative merges blockchain, NFTs, and logistics, empowering holders with data control and immersive learning.",
  },
  {
    imgsrc: "/assets/images/project2.webp",
    title: "BRETT",
    para: "Brett is a meme coin project inspired by Pepe, originating from the Boy's Club. Empowering memes, it features custom Brt tokens with a no-tax exchange policy, simply paying homage to the meme culture.",
  },
  {
    imgsrc: "/assets/images/project3.webp",
    title: "BLOCKNOTES",
    para: "Blocknotes is a Web3 platform for the music industry, enables trading audio files as NFTs with customizable profiles. Utilizing Solana, it offers a custom staking platform where NFT owners earn 'NOTES' for future benefits.",
  },
  {
    imgsrc: "/assets/images/project4.webp",
    title: "NEO AI",
    para: "NEO AI serves to be the pinnacle of automatic liquidity acquisition yield farming and decentralized exchange on Binance Smart Chain. Packed with unique and creative features, it facilitates network growth and earnings.",
  },
  {
    imgsrc: "/assets/images/project5.webp",
    title: "TOY’S COVE",
    para: "Toy's Cove is the ultimate toy destination for kids. Explore figures, educational puzzles, and plush from renowned brands, streamlining the buying process with ease. Experience the joy of making every purchase a delightful adventure.",
  },
  {
    imgsrc: "/assets/images/project6.png",
    title: "TIME VILLAGE",
    para: "Time Village is a decentralized social media platform for Web 3.0, empowering users to take control with NFTs and shape their own community. Utilizing AI and blockchain, it ensures secure, decentralized online interaction.",
  },
];
