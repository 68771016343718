import React from "react";
import "./banner.scss";
function Banner({ imgsrc, vidsrc, title, layer, className}) {
  return (
    <section className="banner-sec bg-black">
      <img src={layer} alt="" className="banner-layer" />
      <h1>{title}</h1>
      {imgsrc ? (
        <img src={imgsrc} alt="" className="backimg" />
      ) : vidsrc ? (
        <video
          muted
          autoPlay
          loop
          playsInline
          id="videoElement"
          className="back-video"
        >
          <source src={vidsrc} type="video/mp4" />
        </video>
      ) : (
        ""
      )}
    </section>
  );
}

export default Banner;
