import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { A11y, Navigation, Parallax } from "swiper/modules";
import EffectCarousel from "./effect-carousel.esm.js";
import "swiper/css";
import "swiper/css/a11y";
import "swiper/css/navigation";
import "swiper/css/parallax";

import "./swiper.css";

export default function Testimonials() {
  const swiperParameters = {
    modules: [A11y, Navigation, Parallax, EffectCarousel],
    navigation: { hideOnClick: true, enabled: true },
    loop: true,
    watchSlidesProgress: true,
    breakpoints: {
      768: {
        slidesPerView: "auto",
        navigation: { hideOnClick: false },

        onBeforeInit(swiper) {
          swiper.params.carouselEffect = { sideSlides: 1 };
        },
      },
    },
    effect: "carousel",
    slidesPerGroupAuto: false,
    parallax: { enabled: true },
    centeredSlides: true,
  };
  return (
    <>
      <div className="testimonials-sec">
        <div className="head">
          <h3>Testimonials</h3>
        </div>

        <Swiper {...swiperParameters}>
          <SwiperSlide className="swiper-slide-cf25">
            <div className="testimonial-card">
              <div className="left">
                <img src="/assets/images/review1.webp" alt="..." />
              </div>
              <div className="right">
                <img
                  src="/assets/images/testimonialimg.webp"
                  alt=""
                  className="testimonailcard-img"
                />
                <h4>Alan Gomez</h4>
                <h6>
                  Neolin Tech's seamless integration of blockchain technology
                  elevated our operations, ensuring security and trust in our
                  transactions exceptional service.
                </h6>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide className="swiper-slide-cf25">
            <div className="testimonial-card">
              <div className="left">
                <img src="/assets/images/review2.webp" alt="..." />
              </div>
              <div className="right">
                <img
                  src="/assets/images/testimonialimg.webp"
                  alt=""
                  className="testimonailcard-img"
                />
                <h4>Daniel Mandoza</h4>
                <h6>
                  Impressed by Neolin Tech's AI solutions; they revolutionized
                  our processes, enhancing productivity and decision-making. A
                  game-changer for our business.
                </h6>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide className="swiper-slide-cf25">
            <div className="testimonial-card">
              <div className="left">
                <img src="/assets/images/review3.webp" alt="..." />
              </div>
              <div className="right">
                <img
                  src="/assets/images/testimonialimg.webp"
                  alt=""
                  className="testimonailcard-img"
                />
                <h4>Martin Tsunoda</h4>
                <h6>
                  Neolin Tech's dedication to innovation is evident in their
                  metaverse solutions. Their visionary approach is reshaping the
                  digital landscape. Truly ahead of the curve.
                </h6>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>

        <img
          src="/assets/images/testimonialleft.png"
          alt=""
          className="test-leftback"
        />
        <img
          src="/assets/images/testimonialright.png"
          alt=""
          className="test-rightback"
        />
      </div>
    </>
  );
}
