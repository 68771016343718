import React from 'react'
import "./homeservicesnew.scss"
const Homeservicesnew = () => {
  return (
    <div className='services-new-main'>
        <div className="heading-container">
        <div className="services-heading">
            <h2>Our Services</h2>
        </div>
        </div>

        <div className="services-card-container">
            <div className="service-card">
                <div className="card-image">
                    <img src="/assets/images/accordian1.webp"alt="" />
                </div>
                <div className="service-content">
                    <h3>
                        Blockchain Development
                    </h3>
                    <p>
                    Neolin Tech pioneers decentralized solutions, utilizing blockchain to fortify transactions, enhance processes, and foster trust through transparent data management.
                    </p>
                </div>
            </div>
            <div className="service-card">
                <div className="card-image">
                    <img src="/assets/images/accordian2.webp"alt="" />
                </div>
                <div className="service-content">
                    <h3>
                       Artificial Intelligence
                    </h3>
                    <p>
                    Neolin Tech leads with AI prowess, advancing natural language processing, image recognition, and machine learning to revolutionize automation and decision-making.
                    </p>
                </div>
            </div>
            <div className="service-card">
                <div className="card-image">
                    <img src="/assets/images/accordian3.webp"alt="" />
                </div>
                <div className="service-content">
                    <h3>
                        Metaverse
                    </h3>
                    <p>
                    Avant-gardes in the metaverse space, Neolin Tech connects users through augmented reality, virtual reality, and the internet. We not only create shared virtual spaces but also blur boundaries between physical and digital realms for unparalleled interactive experiences.
                    </p>
                </div>
            </div>
            <div className="service-card">
                <div className="card-image">
                    <img src="/assets/images/accordian4.webp"alt="" />
                </div>
                <div className="service-content">
                    <h3>
                        Game Development
                    </h3>
                    <p>
                    Neolin Tech is a contributor in creative game development, using new technologies like virtual reality and eye-catching graphics to create immersive and entertaining gaming experiences for various platforms.
                    </p>
                </div>
            </div>
            <div className="service-card">
                <div className="card-image">
                    <img src="/assets/images/accordian5.webp"alt="" />
                </div>
                <div className="service-content">
                    <h3>
                       DevOps Services
                    </h3>
                    <p>
                    Our collaborative approach, automation, and continuous delivery ensure efficiency and high-quality outcomes for all of our projects.
                    </p>
                </div>
            </div>
            <div className="service-card">
                <div className="card-image">
                    <img src="/assets/images/accordian6.webp"alt="" />
                </div>
                <div className="service-content">
                    <h3>
                        Sales & Marketing
                    </h3>
                    <p>
                    In the digital age, Sales and Marketing includes online and offline tactics, leveraging social media, content marketing, and data analytics to enhance customer engagement and drive business growth.
                    </p>
                </div>
            </div>
           
        </div>
        
    </div>
  )
}

export default Homeservicesnew
