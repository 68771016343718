import React from "react";
import Aboutcontent from "../aboutcontent/aboutcontent";
import Counter from "../counter/counter";
import Banner from "../banner/banner";

function Aboutuspage() {
  return (
    <section className="about-sec">
      <Banner
        imgsrc="/assets/images/aboutuspage.webp"
        title="Our Story"
        layer="/assets/images/bannerlayer.webp"
      />
      <Aboutcontent />
      <Counter />
    </section>
  );
}

export default Aboutuspage;
