import React, { useEffect } from "react";
import "./blog.scss";
import { useLocation } from "react-router-dom";
function Blog() {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <section className="blog-sec">
      <div className="inner">
        <img src={location?.state?.imgsrc} alt="..." className="top" />
        <div className="center">
          <h3>{location?.state?.title}</h3>
          <div className="content">
            <h6>{location?.state?.para}</h6>
          </div>
        </div>
        {/* <div className="bottom">
          <img src="/assets/images/blog2.webp" alt="..." />
          <h6>
            Web 3.0 isn't a distant vision; it's already making waves in
            decentralized finance (DeFi), non-fungible tokens (NFTs), and the
            metaverse, promising a more inclusive, secure, and user-centric
            internet experience. As we step into this era, embracing change,
            adapting to new technologies, and actively participating in the
            ongoing dialogue will be crucial. The journey ahead is filled with
            possibilities and innovations that will redefine how we engage with
            the digital world, making Web 3.0 not just the next chapter but a
            whole new narrative in the story of the internet. Buckle up as we
            embark on this thrilling journey into the digital frontier, where
            the future of the internet unfolds before our eyes.
          </h6>
        </div> */}
      </div>
      <img src="/assets/images/leftback.webp" alt="..." className="left-back" />
      <img
        src="/assets/images/rightback.webp"
        alt="..."
        className="right-back"
      />
    </section>
  );
}

export default Blog;
