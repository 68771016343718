import React from "react";
import "./whyus.scss";

function Whyus() {
  return (
    <section className="whyus-sec">
      <div className="head">
        <h3>Why Choose Us</h3>
      </div>
      <div className="whyus-cards">
        {data.map((item) => (
          <div className="whyus-card">
            <div className="topbox"></div>
            <img src={item.imgsrc} alt="..." className="card-icon" />
            <img src={item.hoversrc} alt="..." className="hover-icon" />
            <img src="" alt="" />
            <h6>{item.para}</h6>
            <div className="bottombox"></div>
          </div>
        ))}
      </div>
    </section>
  );
}

export default Whyus;

const data = [
  {
    imgsrc: "/assets/icons/whyus1.svg",
    hoversrc: "/assets/icons/whyus11.svg",
    para: "Innovative solutions tailored to your unique business needs and objectives.",
  },
  {
    imgsrc: "/assets/icons/whyus2.svg",
    hoversrc: "/assets/icons/whyus22.svg",
    para: "Dedicated team committed to providing exceptional service and ongoing support.",
  },
  {
    imgsrc: "/assets/icons/whyus3.svg",
    hoversrc: "/assets/icons/whyus33.svg",
    para: "Transparent pricing and flexible service options tailored to fit your budget and goals.",
  },
];
